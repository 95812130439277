<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil :localNavigation="false" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
           
            <div class="bg-container col-span-6">
                <Transition>
                    <div v-if="isLoading" class="w-full h-full">
                        
                        <img src="@/assets/Cuenta/vidaSimple/foncabsa.svg" class="logo_cabsa mx-auto my-32 animate-pulse" />
                    </div>
                    <div v-else class="w-full md:w-4/5 mx-auto h-auto py-16 grid grid-cols-1 md:grid-cols-2">
                        <div class="px-10 md:px-5 lg:px-5">
                            <p class="text-3xl font-bold mb-10 RobotoBold">Cotización</p>
                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 h-32">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Prima Total</p>
                                    <div class="grid grid-cols-2">
                                        <div class="flex"><p class="RobotoLight text-md text-black mr-2 mt-auto mb-2">{{ $filters.formatCurrencyWithDecimal(quoteDetail.total_anual) }} </p> <span class="text-gray-400 mt-auto mb-2">Anual</span></div>
                                        <div class="flex"><p class="RobotoLight text-md text-black mr-2 mt-auto mb-2">{{ $filters.formatCurrencyWithDecimal(quoteDetail.total_month)}} </p> <span class="text-gray-400 mt-auto mb-2">Mensual</span></div>
                                    </div>
                                </div>
                            </div>

                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 h-32">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Beneficios Adicionales</p>
                                    <div class="flex">
                                        <span class="RobotoLight text-md text-black mt-auto mb-2 mr-2 uppercase " v-if="quoteDetail.coverages.length==0">Sin beneficios adicionales</span>
                                        <span class="RobotoLight text-md text-black mt-auto mb-2 mr-2 uppercase " v-for="(coverage, index) in quoteDetail.coverages" :key="index">{{ (coverage !== '')? (index == 0)? '': coverage : '' }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 h-32">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Suma Asegurada Total</p>
                                    <div class="grid grid-cols-2">
                                        <div class="flex"><p class="RobotoLight text-md text-black mr-2 mt-auto mb-2">{{ $filters.formatCurrencyWithDecimal(quoteDetail.assured_amount) }} </p> <span class="text-gray-400 mt-auto mb-2">Total</span></div>
                                        <div class="flex"><p class="RobotoLight text-md text-black mr-2 mt-auto mb-2"></p> <span class="text-gray-400 mt-auto mb-2"></span></div>
                                    </div>
                                </div>
                            </div>
                            <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="next" value="Continuar">  
                        </div>
                        
                        <div class="px-10 py-16 hidden md:block">
                            <img :src="require('@/assets/Cuenta/vidaSimple/quotation.svg')" alt="FONCABSA" class="mx-auto">
                        </div>
                    </div>
                </Transition>
                
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onMounted, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue";
import Questions from "@/components/Cuenta/Quote/QuoteQuestions.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';;
import MenuLateral from '@/components/LateralMenu.vue';;
import Footer from '@/components/Footer.vue';
import Loading from '@/components/Loading/VueLoading.vue';

export default {
    setup(){
        const router = useRouter();
        const category = ref('');
        let counterGoBack = ref(0);
        let continueQuote = ref(false);
        const isLoading = ref(true);
        const quoteDetail = ref({
            assured_amount: 0,
            total_anual: 0,
            total_month: 0,
            coverages: []
        });
        
        onBeforeMount(()=>{
            category.value = router.currentRoute.value.params.category;
            if(category.value!==""){

                const detail = getStorage('quote-detail');
                if(detail != null){
                    quoteDetail.value.total_anual = detail.data.total_anual;
                    quoteDetail.value.assured_amount = detail.parameters.assured_amount;
                    quoteDetail.value.total_month = (detail.data.total_anual)? detail.data.total_anual / 12 : 0;
                    quoteDetail.value.coverages = detail.parameters.coverages;
                }
               
            }
        });

        onMounted(()=>{
            setTimeout(() => {
                isLoading.value = false;
            }, 2000);
        });

        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }
        
        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
            console.log("goToBack", counterGoBack.value)
        }

        const next = () => {
            router.push({ path: `/cuenta/emision/${category.value}`})
        }

        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueQuote,
            isLoading,
            quoteDetail,
            next
        }
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer,
        Questions,
        Loading
    }
}
</script>

<style scoped>
#logo_cabsa{
    animation: zoom_in_and_out 4s infinite forwards
}

@keyframes zoom_in_and_out{

    0% {
        max-width:200px;
        height: 200px;
    }

    25% {
        max-width:300px;
        height: 300px;
    }

    50% {
        max-width:200px;
        height: 200px;
    }

    75% {
        max-width:300px;
        height: 300px;
    }

    100% {
        max-width:200px;
        height: 200px;
    }
}
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0.3;
}
button {
    font-size: 19px!important;
}
</style>